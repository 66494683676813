import { render, staticRenderFns } from "./tab_educacion_formal.vue?vue&type=template&id=28a665f9&scoped=true"
import script from "./tab_educacion_formal.vue?vue&type=script&lang=js"
export * from "./tab_educacion_formal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a665f9",
  null
  
)

export default component.exports