<!-- experiencia profesional -->
<template>
  <div v-if="tabs == 4">
    <div class="p-3">
      <!-- existentes -->
      <form @submit.prevent="sendUpdateProfessionalExperience">
        <div v-for="(
                      itemExperienciaProfesional, ef1
                    ) of experienciaProfesional" :key="itemExperienciaProfesional.id">
          <p class="text-center">
            Ref. Experiencia Profesional {{ ef1 + 1 }}
          </p>
          <div class="d-flex justify-content-end">
            <span class="pointer btn btn-outline-danger m-1" @click="
              deleteItem(
                itemExperienciaProfesional.id,
                'ProfessionalExperience/Delete/'
              )
              ">
              <i class="fas fa-solid fa-trash px-1"></i>Eliminar
            </span>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombre Empresa</label>
              <input required v-model="itemExperienciaProfesional.companyName" placeholder="Nombres y Apellidos"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Tiempo Laborado (Meses)</label>
              <input required v-model="itemExperienciaProfesional.monthsWorked" placeholder="Tiempo laborado" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Inicio</label>
              <input required @input="
                itemExperienciaProfesional.startDate =
                $event.target.value
                " :value="$moment
    .tz(
      itemExperienciaProfesional.startDate,
      'America/Costa_Rica'
    )
    .format('YYYY-MM-DD')
    " placeholder="" type="date" class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Fin</label>
              <input required @input="
                itemExperienciaProfesional.endDate =
                $event.target.value
                " :value="$moment
    .tz(
      itemExperienciaProfesional.endDate,
      'America/Costa_Rica'
    )
    .format('YYYY-MM-DD')
    " placeholder="" type="date" class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Cargo Desempeñado</label>
              <input required v-model="itemExperienciaProfesional.positionHeld" placeholder="Cargo Desempeñado"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Motivo De Retiro</label>
              <input required v-model="itemExperienciaProfesional.retirementReason" placeholder="Motivo De Retiro"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
              <input required v-model="itemExperienciaProfesional.phoneNumber" placeholder="Teléfono" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Jefe Inmediato</label>
              <input required v-model="itemExperienciaProfesional.inmediateBoss" placeholder="Jefe Inmediato" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select required @change.native="
                getDepartmentoPaisList(
                  itemExperienciaProfesional.countryId,
                  'itemExperienciaProfesional'
                )
                " v-model="itemExperienciaProfesional.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select required v-model="itemExperienciaProfesional.municipalityId" value-field="id"
                text-field="descripcion" :options="itemExperienciaProfesional.MunicipioDepartamento
                  "></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select required v-model="itemExperienciaProfesional.departamentId" value-field="id" @change.native="
                actualizaMunicipios(
                  itemExperienciaProfesional,
                  $event,
                  'itemExperienciaProfesional'
                )
                " text-field="descripcion" :options="itemExperienciaProfesional.DepartamentosPais
    "></b-form-select>
            </div>
          </div>
          <button type="submit" @click="getIdForData(itemExperienciaProfesional.id)"
            class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
        </div>
      </form>

      <!-- nuevos -->
      <form @submit.prevent="createProfessionalExperience">
        <div v-show="isCreatedProfessionalExperience">
          <p class="text-center">
            <b-badge class="mx-1" variant="success">Nueva</b-badge>Experiencia Profesional
          </p>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombre Empresa</label>
              <input required v-model="newExperienciaProfesional.companyName" placeholder="Nombres y Apellidos"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Tiempo Laborado (Meses)</label>
              <input required v-model="newExperienciaProfesional.monthsWorked" placeholder="Tiempo laborado" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Inicio</label>
              <input required v-model="newExperienciaProfesional.startDate" placeholder="" type="date"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Fin</label>
              <input required v-model="newExperienciaProfesional.endDate" placeholder="" type="date" class="form-control"
                id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Cargo Desempeñado</label>
              <input required v-model="newExperienciaProfesional.positionHeld" placeholder="Cargo Desempeñado" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Motivo De Retiro</label>
              <input required v-model="newExperienciaProfesional.retirementReason" placeholder="Motivo De Retiro"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
              <input required v-model="newExperienciaProfesional.phoneNumber" placeholder="Teléfono" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Jefe Inmediato</label>
              <input required v-model="newExperienciaProfesional.inmediateBoss" placeholder="Jefe Inmediato" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select required @change.native="
                getDepartmentoPaisList(
                  newExperienciaProfesional.countryId,
                  'newExperienciaProfesional'
                )
                " v-model="newExperienciaProfesional.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select required v-model="newExperienciaProfesional.municipalityId" value-field="id"
                text-field="descripcion" :options="dataLocation.municipios"></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select required v-model="newExperienciaProfesional.departamentId" @change.native="
                actualizaMunicipios(
                  newExperienciaProfesional,
                  $event,
                  'newExperienciaProfesional'
                )
                " value-field="id" text-field="descripcion" :options="dataLocation.departamentos"></b-form-select>
            </div>
          </div>
          <button type="submit" class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
          <button class="btn btn-outline-danger my-4 btn px-4 py-2" @click="closeCreateProfessionalExperience">
            Cancelar
          </button>
        </div>
      </form>
      <div class="col-xl-12 pt-3 text-right">
        <button ref="addNewProfessionalExperience" v-show="!isCreatedProfessionalExperience"
          @click="initDataInfoProfessionalExperience" class="mx-1 btn px-4 py-2 btn-success">
          Agregar Nueva
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true
    },
    experienciaProfesional: {
      type: Array,
      required: true
    },
    newExperienciaProfesional: {
      type: Object,
      required: true
    },
    listaPaises: {
      type: Array,
      required: true
    },
    dataLocation: {
      type: Object,
      required: true
    },
    isCreatedProfessionalExperience: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    sendUpdateProfessionalExperience() {
      this.$emit('sendUpdateProfessionalExperience', this.experienciaProfesional);
    },
    deleteItem(itemId, endpoint) {
      this.$emit('deleteItem', itemId, endpoint);
    },
    createProfessionalExperience() {
      this.$emit('createProfessionalExperience', this.newExperienciaProfesional);
    },
    getIdForData(id) {
      this.$emit('getIdForData', id);
    },
    getDepartmentoPaisList(countryId, itemKey) {
      this.$emit('getDepartmentoPaisList', countryId, itemKey);
    },
    actualizaMunicipios(item, event, itemKey) {
      this.$emit('actualizaMunicipios', item, event, itemKey);
    },
    initDataInfoProfessionalExperience() {
      this.$emit('initDataInfoProfessionalExperience');
    },
    closeCreateProfessionalExperience() {
      this.$emit('closeCreateProfessionalExperience');
    }
  }
}
</script>

<style scoped></style>
