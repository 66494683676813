<!-- referencias personales -->
<template>
  <div v-if="tabs == 6">
    <div class="p-3">
      <!-- existentes -->
      <form @submit.prevent="sendUpdatePersonalReferences">
        <div v-for="(
                      itemReferenciaPersonal, enf1
                    ) of referenciasPersonales" :key="itemReferenciaPersonal.id">
          <p class="text-center">
            Ref. Referencia Personal {{ enf1 + 1 }}
          </p>
          <div class="d-flex justify-content-end">
            <span class="pointer btn btn-outline-danger m-1" @click="
              deleteItem(
                itemReferenciaPersonal.id,
                'PersonalReferences/Delete/'
              )
              ">
              <i class="fas fa-solid fa-trash px-1"></i>Eliminar
            </span>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombres y Apellidos</label>
              <input required v-model="itemReferenciaPersonal.fullName" placeholder="Nombres y Apellidos" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Ocupación</label>
              <input required v-model="itemReferenciaPersonal.occupation" placeholder="Ingrese ocupación" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
              <input required v-model="itemReferenciaPersonal.phoneNumber" placeholder="Teléfono" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Dirección de Domicilio</label>
              <input required v-model="itemReferenciaPersonal.residenceAddress" placeholder="Ingrese Dirección"
                type="text" class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select required @change.native="
                getDepartmentoPaisList(
                  itemReferenciaPersonal.countryId,
                  'itemReferenciaPersonal'
                )
                " v-model="itemReferenciaPersonal.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select v-model="itemReferenciaPersonal.municipalityId" required value-field="id"
                text-field="descripcion" :options="itemReferenciaPersonal.MunicipioDepartamento
                  "></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select required v-model="itemReferenciaPersonal.departamentId" @change.native="
                actualizaMunicipios(
                  itemReferenciaPersonal,
                  $event,
                  'itemReferenciaPersonal'
                )
                " value-field="id" text-field="descripcion"
                :options="itemReferenciaPersonal.DepartamentosPais"></b-form-select>
            </div>
          </div>
          <button type="submit" @click="getIdForData(itemReferenciaPersonal.id)"
            class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
        </div>
      </form>

      <!-- nuevos -->
      <form @submit.prevent="createPersonalReferences">
        <div v-show="isCreatedPersonalReferences">
          <p class="text-center">
            <b-badge class="mx-1" variant="success">Nueva</b-badge>
            Referencia Personal
          </p>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombres y Apellidos</label>
              <input required v-model="newPersonalReferences.fullName" placeholder="Nombres y Apellidos" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Ocupación</label>
              <input required v-model="newPersonalReferences.occupation" placeholder="Ingrese ocupación" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
              <input required v-model="newPersonalReferences.phoneNumber" placeholder="Teléfono" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Dirección de Domicilio</label>
              <input required v-model="newPersonalReferences.residenceAddress" placeholder="Ingrese Dirección" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select required @change.native="
                getDepartmentoPaisList(
                  newPersonalReferences.countryId,
                  'newPersonalReferences'
                )
                " v-model="newPersonalReferences.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select required v-model="newPersonalReferences.municipalityId" value-field="id"
                text-field="descripcion" :options="dataLocation.municipios"></b-form-select>
            </div>
            <div class="col-md-6 pt-3">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select required v-model="newPersonalReferences.departamentId" value-field="id" @change.native="
                actualizaMunicipios(
                  newPersonalReferences,
                  $event,
                  'newPersonalReferences'
                )
                " text-field="descripcion" :options="dataLocation.departamentos"></b-form-select>
            </div>
          </div>
          <button type="submit" class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
          <button class="btn btn-outline-danger my-4 btn px-4 py-2" @click="closeCreatePersonalReferences">
            Cancelar
          </button>
        </div>
      </form>
      <div class="col-xl-12 pt-3 text-right">
        <button ref="addNewPersonalReferences" v-show="!isCreatedPersonalReferences"
          @click="initDataInfoPersonalReferences" class="mx-1 btn px-4 py-2 btn-success">
          Agregar Nueva
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true
    },
    referenciasPersonales: {
      type: Array,
      required: true
    },
    newPersonalReferences: {
      type: Object,
      required: true
    },
    listaPaises: {
      type: Array,
      required: true
    },
    dataLocation: {
      type: Object,
      required: true
    },
    isCreatedPersonalReferences: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    sendUpdatePersonalReferences() {
      this.$emit('sendUpdatePersonalReferences', this.referenciasPersonales);
    },
    deleteItem(itemId, endpoint) {
      this.$emit('deleteItem', itemId, endpoint);
    },
    createPersonalReferences() {
      this.$emit('createPersonalReferences', this.newPersonalReferences);
    },
    getIdForData(id) {
      this.$emit('getIdForData', id);
    },
    getDepartmentoPaisList(countryId, itemKey) {
      this.$emit('getDepartmentoPaisList', countryId, itemKey);
    },
    actualizaMunicipios(item, event, itemKey) {
      this.$emit('actualizaMunicipios', item, event, itemKey);
    },
    initDataInfoPersonalReferences() {
      this.$emit('initDataInfoPersonalReferences');
    },
    closeCreatePersonalReferences() {
      this.$emit('closeCreatePersonalReferences');
    }
  }
}
</script>

<style scoped></style>
