<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>Curriculum Vitae</b></h4>
          </template>

          <div class="p-5">
            <!-- tabs por categorias -->
            <div class="buttons btn-group">
              <button :class="tabs == 1
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 1;
  " type="button">
                <b>Datos Personales</b>
              </button>

              <button :class="tabs == 2
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 2;
  " type="button">
                <b>Educación Formal</b>
              </button>

              <button :class="tabs == 3
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 3;
  " type="button">
                <b>Educación No Formal</b>
              </button>

              <button :class="tabs == 4
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 4;
  " type="button">
                <b>Experiencia Profesional</b>
              </button>

              <button :class="tabs == 5
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 5;
  " type="button">
                <b>Información Familiar</b>
              </button>
              <button :class="tabs == 6
                ? 'btn btn-primary btn-lg active  rounded-top'
                : ' btn btn-primary btn-lg  rounded-top'
                " @click="
    tabs = 6;
  " type="button">
                <b>Referencias Personales</b>
              </button>
            </div>

            <!-- datos personales -->
            <tab_datos_personales :tabs="tabs" :persona="persona" :informacionPersonal="informacionPersonal"
              :listaTipoDocumento="listaTipoDocumento" :listaCategoriaLicencia="listaCategoriaLicencia"
              @submitForm="sendDataPersonalInformation" />

            <!-- educacion formal -->
            <tab_educacion_formal :tabs="tabs" :educacionFormal="educacionFormal" :dataLocation="dataLocation"
              :isCreated="isCreated" :listaPaises="listaPaises" :newEducacionFormal="newEducacionFormal"
              :NivelesAcademicos="NivelesAcademicos" @sendUpdateFormalEducation="sendUpdateFormalEducation"
              @deleteItem="deleteItem" @createFormalEducation="createFormalEducation" @getIdForData="getIdForData"
              @getDepartmentoPaisList="getDepartmentoPaisList" @actualizaMunicipios="actualizaMunicipios"
              @initDataInfoEducationFormal="initDataInfoEducationFormal" @closeCreate="closeCreate" />

            <!-- educacion informal -->
            <tab_educacion_informal :tabs="tabs" :educacionNoFormal="educacionNoFormal" :dataLocation="dataLocation"
              :isCreatedInformal="isCreatedInformal" :listaPaises="listaPaises"
              :newEducacionInformal="newEducacionInformal" @sendUpdateInformalEducation="sendUpdateInformalEducation"
              @deleteItem="deleteItem" @createInformalEducation="createInformalEducation" @getIdForData="getIdForData"
              @getDepartmentoPaisList="getDepartmentoPaisList" @actualizaMunicipios="actualizaMunicipios"
              @initDataInfoEducationInformal="initDataInfoEducationInformal" @closeCreateInformal="closeCreateInformal" />

            <!-- experiencia profesional -->
            <tab_experiencia_profesional :tabs="tabs" :experienciaProfesional="experienciaProfesional"
              :dataLocation="dataLocation" :isCreatedProfessionalExperience="isCreatedProfessionalExperience"
              :listaPaises="listaPaises" :newExperienciaProfesional="newExperienciaProfesional"
              @sendUpdateProfessionalExperience="sendUpdateProfessionalExperience
                " @deleteItem="deleteItem" @createProfessionalExperience="createProfessionalExperience"
              @getIdForData="getIdForData" @getDepartmentoPaisList="getDepartmentoPaisList"
              @actualizaMunicipios="actualizaMunicipios" @initDataInfoProfessionalExperience="initDataInfoProfessionalExperience
                " @closeCreateProfessionalExperience="closeCreateProfessionalExperience
    " />

            <!-- informacion familiar -->
            <tab_informacion_familiar :tabs="tabs" :informacionFamiliar="informacionFamiliar" :dataLocation="dataLocation"
              :isCreatedFamilyInformation="isCreatedFamilyInformation" :listaPaises="listaPaises" :Parentesco="Parentesco"
              :newFamilyInformation="newFamilyInformation" @sendUpdateFamilyInformation="sendUpdateFamilyInformation"
              @deleteItem="deleteItem" @createFamilyInformation="createFamilyInformation" @getIdForData="getIdForData"
              @getDepartmentoPaisList="getDepartmentoPaisList" @actualizaMunicipios="actualizaMunicipios"
              @initDataInfoFamilyInformation="initDataInfoFamilyInformation"
              @closeCreateFamilyInformation="closeCreateFamilyInformation" />

            <!-- referencias personales -->
            <tab_referencias_personales :tabs="tabs" :referenciasPersonales="referenciasPersonales"
              :dataLocation="dataLocation" :isCreatedPersonalReferences="isCreatedPersonalReferences"
              :listaPaises="listaPaises" :newPersonalReferences="newPersonalReferences"
              @sendUpdatePersonalReferences="sendUpdatePersonalReferences" @deleteItem="deleteItem"
              @createPersonalReferences="createPersonalReferences" @getIdForData="getIdForData"
              @getDepartmentoPaisList="getDepartmentoPaisList" @actualizaMunicipios="actualizaMunicipios"
              @initDataInfoPersonalReferences="initDataInfoPersonalReferences"
              @closeCreatePersonalReferences="closeCreatePersonalReferences" />
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import tab_datos_personales from "./tab_datos_personales.vue";
import tab_educacion_formal from "./tab_educacion_formal.vue";
import tab_educacion_informal from "./tab_educacion_informal.vue";
import tab_experiencia_profesional from "./tab_experiencia_profesional.vue";
import tab_informacion_familiar from "./tab_informacion_familiar.vue";
import tab_referencias_personales from "./tab_referencias_personales.vue";
import misMetodos from "./misMetodos";

export default {
  name: "hoja_vida",
  components: {
    tab_datos_personales,
    tab_educacion_formal,
    tab_educacion_informal,
    tab_experiencia_profesional,
    tab_informacion_familiar,
    tab_referencias_personales,
  },
  data() {
    return {
      tabs: 1,
      persona: {},

      validateExistence: [],
      informacionPersonal: {},
      educacionFormal: [],
      educacionNoFormal: [],
      experienciaProfesional: [],
      informacionFamiliar: [],
      referenciasPersonales: [],
      newEducacionFormal: {},
      newEducacionInformal: {},
      newExperienciaProfesional: {},
      newFamilyInformation: {},
      newPersonalReferences: {},
      isCreated: null,
      isCreatedInformal: null,
      isCreatedProfessionalExperience: null,
      isCreatedFamilyInformation: null,
      isCreatedPersonalReferences: null,
      dataLocation: {
        departamentos: [],
        municipios: [],
      },
      // catalogos
      Parentesco: [],
      listaPaises: [],
      listaMunicipios: [],
      listaDepartamentos: [],
      NivelesAcademicos: [],
      listaTipoDocumento: [],
      listaCategoriaLicencia: [],
    };
  },
  async mounted() {
    //this.renameFile('20221103 ó Ó Á a IÍÓÚ.pdf')
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      console.log("ID por parametro de URl", this.$route.params.id);
    } else {
      let personaActiva = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.id = personaActiva.id;
      console.log("ID de usuario activo", personaActiva);
    }
    //this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getcategoriaLicencia();
    await this.getTipoDocumento();
    await this.getvalidateExistence();
    await this.getParentesco();
  },
  methods: {
    ...misMetodos,
  },
};
</script>

<style>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  border-radius: 11px;
}

.buttons>* {
  border-radius: 0;
}

.validacionEnvio {
  margin: 1rem 0;
}
</style>
