<!-- educacion informal -->
<template>
  <div v-if="tabs == 3">
    <div class="p-3">
      <!-- existentes -->
      <form @submit.prevent="sendUpdateInformalEducation">
        <div v-for="(itemEducacionNoFormal, enf1) of educacionNoFormal" :key="itemEducacionNoFormal.id">
          <p class="text-center">
            Ref. Educación Informal {{ enf1 + 1 }}
          </p>
          <div class="d-flex justify-content-end">
            <span class="pointer btn btn-outline-danger m-1" @click="
              deleteItem(
                itemEducacionNoFormal.id,
                'InformalEducation/Delete/'
              )
              ">
              <i class="fas fa-solid fa-trash px-1"></i>Eliminar
            </span>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombre Curso</label>
              <input required v-model="itemEducacionNoFormal.courseName" placeholder="" type="text" class="form-control"
                id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Institución Educativa</label>
              <input required v-model="itemEducacionNoFormal.educationalInstitution" placeholder="" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Finalización</label>
              <input @input="
                itemEducacionNoFormal.endDate = $event.target.value
                " :value="$moment
    .tz(
      itemEducacionNoFormal.endDate,
      'America/Costa_Rica'
    )
    .format('YYYY-MM-DD')
    " placeholder="nombre" type="date" class="form-control" id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Horas de Formación</label>
              <input required v-model="itemEducacionNoFormal.trainingHours" placeholder="" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select @change.native="
                getDepartmentoPaisList(
                  itemEducacionNoFormal.countryId,
                  'itemEducacionNoFormal'
                )
                " v-model="itemEducacionNoFormal.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select v-model="itemEducacionNoFormal.municipalityId" required value-field="id"
                text-field="descripcion" :options="itemEducacionNoFormal.MunicipioDepartamento"></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select v-model="itemEducacionNoFormal.departamentId" required @change.native="
                actualizaMunicipios(
                  itemEducacionNoFormal,
                  $event,
                  'itemEducacionNoFormal'
                )
                " value-field="id" text-field="descripcion"
                :options="itemEducacionNoFormal.DepartamentosPais"></b-form-select>
            </div>
          </div>
          <button type="submit" @click="getIdForData(itemEducacionNoFormal.id)"
            class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
        </div>
      </form>
      <!-- nuevos -->
      <form @submit.prevent="createInformalEducation">
        <div v-show="isCreatedInformal">
          <p class="text-center">
            <b-badge class="mx-1" variant="success">Nueva</b-badge>Educación NoFormal
          </p>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Nombre Curso</label>
              <input required v-model="newEducacionInformal.courseName" placeholder="" type="text" class="form-control"
                id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Institución Educativa</label>
              <input required v-model="newEducacionInformal.educationalInstitution" placeholder="" type="text"
                class="form-control" id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Fecha Finalización</label>
              <input required v-model="newEducacionInformal.endDate" placeholder="nombre" type="date" class="form-control"
                id="exampleFormControlInput1" />
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Horas de Formación</label>
              <input required v-model="newEducacionInformal.trainingHours" placeholder="" type="text" class="form-control"
                id="exampleFormControlInput1" />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">País</label>
              <b-form-select required @change.native="
                getDepartmentoPaisList(
                  newEducacionInformal.countryId,
                  'newEducacionInformal'
                )
                " v-model="newEducacionInformal.countryId" value-field="id" text-field="descripcion"
                :options="listaPaises"></b-form-select>
            </div>
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Municipio</label>
              <b-form-select v-model="newEducacionInformal.municipalityId" required value-field="id"
                text-field="descripcion" :options="dataLocation.municipios"></b-form-select>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-md-6">
              <label for="exampleFormControlInput1" class="form-label">Departamento</label>
              <b-form-select v-model="newEducacionInformal.departamentId" required @change.native="
                actualizaMunicipios(
                  newEducacionInformal,
                  $event,
                  'newEducacionInformal'
                )
                " value-field="id" text-field="descripcion" :options="dataLocation.departamentos"></b-form-select>
            </div>
          </div>
          <button type="submit" class="mx-1 my-4 btn px-4 py-2 btn-primary">
            Guardar
          </button>
          <button class="btn btn-outline-danger my-4 btn px-4 py-2" @click="closeCreateInformal">
            Cancelar
          </button>
        </div>
      </form>
      <div class="col-xl-12 pt-3 text-right">
        <button ref="addNewInformal" v-show="!isCreatedInformal" @click="initDataInfoEducationInformal"
          class="mx-1 btn px-4 py-2 btn-success">
          Agregar Nueva
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Number,
      required: true
    },
    educacionNoFormal: {
      type: Array,
      required: true
    },
    newEducacionInformal: {
      type: Object,
      required: true
    },
    listaPaises: {
      type: Array,
      required: true
    },
    dataLocation: {
      type: Object,
      required: true
    },
    isCreatedInformal: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    sendUpdateInformalEducation() {
      this.$emit('sendUpdateInformalEducation', this.educacionNoFormal);
    },
    deleteItem(itemId, endpoint) {
      this.$emit('deleteItem', itemId, endpoint);
    },
    createInformalEducation() {
      this.$emit('createInformalEducation', this.newEducacionInformal);
    },
    getIdForData(id) {
      this.$emit('getIdForData', id);
    },
    getDepartmentoPaisList(countryId, itemKey) {
      this.$emit('getDepartmentoPaisList', countryId, itemKey);
    },
    actualizaMunicipios(item, event, itemKey) {
      this.$emit('actualizaMunicipios', item, event, itemKey);
    },
    initDataInfoEducationInformal() {
      this.$emit('initDataInfoEducationInformal');
    },
    closeCreateInformal() {
      this.$emit('closeCreateInformal');
    }
  }
}
</script>

<style scoped></style>
